<template>
  <div>
    <!-- 酒店列表的条目 -->
    <div v-for="(ticketInfo,i) in list" :key="i" class="itembk">
      <van-row type="flex" justify="center">
        <van-row
          @click="chooseTicket(ticketInfo)"
          class="item-top"
          type="flex"
          justify="space-between"
          align="center"
        >
          <van-col span="6" style="overflow:hidden;height:110px">
            <van-image :src="ticketInfo.hotelPic" height="110" />
          </van-col>
          <van-col span="12" class="info">
            <p class="list-title van-ellipsis">{{ticketInfo.chineseName}}</p>
              <!-- <span class="blue">{{ticketInfo.commentScore}}</span> -->
            <p class="list-text van-multi-ellipsis--l2" v-if="ticketInfo.tel">联系电话：{{ticketInfo.tel}}</p>
            <p class="list-text" v-if="ticketInfo.distance">距目的地：{{ticketInfo.distance}}公里</p>
            <p class="list-text van-multi-ellipsis--l2">详细地址：{{ticketInfo.address}}</p>
          </van-col>
          <van-col span="6" style="padding-bottom:1rem">
            <van-row>
              <span style="color: red;">￥</span>
              <span style="color: red;font-size: 1.2rem">{{ticketInfo.price||'- '}}</span>
              <span class="info-text">起</span>
            </van-row>
            <!--服务商标签-->
            <van-row type="flex" justify="center">
              <Logo :corp="ticketInfo.corp" />
            </van-row>
          </van-col>
        </van-row>
      </van-row>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
export default {
  name: "TicketItem",
  props: {
    list: Array,
    type: Boolean
  },
  components: { Logo },
  methods: {
    chooseTicket(data) {
      this.$emit("childClick", data);
    }
  }
};
</script>

<style lang="less" src="@/styles/itemCommon.less" scoped></style>
<style lang="less" scoped>
.info {
  height: calc(100% - 4px);
  padding: 2px 10px;
  text-align: left;
  .big {
    font-size: 16px;
    font-weight: 600;
    padding-right: 4px;
  }
}
.item-top {
  padding: 0;
}
.itembk{
  border-radius: 0;
}
</style>