<template>
  <van-row class="searchTrain">
    <!--头部检索bar-->
    <van-sticky>
      <div style="background: linear-gradient(to right, rgb(75, 176, 255), rgb(44, 80, 212));">
        <van-search
          background="rgba(0,0,0,0)"
          v-model="hotelOrderInfo.keyword"
          show-action
          placeholder="搜索目的地"
          @search="onRefresh"
        >
          <template #label>
            <div class="top-city" @click="chooseCity()">
              <div class="deep-blue">{{hotelOrderInfo.city}}</div>
            </div>
          </template>
          <template #left-icon>
            <div style="display:flex">
              <div class="top-date" @click="show_calendar=true">
                <div>
                  入住：
                  <span class="deep-blue">{{hotelOrderInfo.checkInDate|comverTime('MM-DD')}}</span>
                </div>
                <div>
                  离开：
                  <span class="deep-blue">{{hotelOrderInfo.departDate|comverTime('MM-DD')}}</span>
                </div>
              </div>
              <van-icon name="search" class="top-icon" />
            </div>
          </template>
          <template #action>
            <div @click="onRefresh" style="color:#fff">搜索</div>
          </template>
        </van-search>
      </div>
    </van-sticky>

    <!-- 列表 -->
    <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
    <van-skeleton :row-width="skeletonArr" :row="4*4" :loading="isLoading" class="skeleton-cont">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="havemore?'加载中，请稍后...':'没有更多了'"
        @load="onLoad"
      >
        <List :list="showList" @childClick="chooseTicket($event)" />
      </van-list>
      <van-empty v-show="!(0 in showList)" :description="havemore?'正在查找，请稍后...':'暂无酒店'" />
    </van-skeleton>
    <!-- </van-pull-refresh> -->

    <!--底部垫底用，对付Tabbar-->
    <van-row style="height: 46px; width:100%"></van-row>

    <!--筛选bar-->
    <van-tabbar style="border:1px solid #eee" v-model="activenav" safe-area-inset-bottom>
      <van-tabbar-item
        name="money"
        class="navicon"
        icon-prefix="icon"
        icon="feiyongbaohan"
        replace
        @click="sort=(sort=='PA'?'PD':'PA');sortSelf()"
      >价格</van-tabbar-item>
      <van-tabbar-item
        @click="sort='DA';sortSelf()"
        name="time"
        class="navicon"
        icon-prefix="icon"
        icon="neirong"
        replace
      >距离</van-tabbar-item>
      <van-tabbar-item
        name="filter"
        class="navicon"
        icon-prefix="icon"
        icon="shaixuan"
        replace
        :dot="0 in ticket_filter_activeIds"
        @click="ticket_filter_show = true"
      >筛选</van-tabbar-item>
    </van-tabbar>

    <!--日期选择-->
    <van-calendar
      v-model="show_calendar"
      color="#198BDA"
      type="range"
      :show-confirm="false"
      confirm-disabled-text="请选择出发时间"
      :default-date="[new Date(hotelOrderInfo.checkInDate),new Date(hotelOrderInfo.departDate)]"
      position="right"
      :round="false"
      @confirm="calendarConfirm"
    />

    <!--筛选选项卡-->
    <van-action-sheet v-model="ticket_filter_show" title="筛选" safe-area-inset-bottom>
      <h5 class="slider-title">已选价格：{{priceRange|showPriceRange}}</h5>
      <div class="slider-cont">
        <el-slider
          :step="50"
          :max="1000"
          v-model="priceRange"
          range
          :marks="marks"
          :show-tooltip="false"
        ></el-slider>
      </div>
      <van-divider />
      <div class="tab_bar_filter_content">
        <van-tree-select
          height="70vw"
          :items="ticket_filter_items"
          :active-id.sync="filter_activeIds"
          :main-active-index.sync="ticket_filter_activeIndex"
        />
      </div>
      <van-row type="flex" justify="space-around" align="center" style="margin-bottom: 10px">
        <van-button style="width: 100px" plain type="info" @click="ticket_filter_activeIds=[]">清空</van-button>
        <van-button style="width: 100px" type="info" @click="filter">确认</van-button>
      </van-row>
    </van-action-sheet>
    <!-- 遮罩层 -->
    <LoadingCircle :show="hideLoading" />
  </van-row>
</template>

<script>
import Vue from "vue";
import { getHotelDistrict } from "@/api/hotel";
import logic from "@/services/commonLogic";
import List from "./components/TicketItem";
import { mapGetters } from "vuex";
import { Slider } from "element-ui";
import { ToTop } from "@/services/utils";
import SkeletonArr from "@/common/Skeleton.js";
import LoadingCircle from "@/components/LoadingCircle";
import { init, searchPosition } from "@/services/hotel";
import { HotelServiceProvider } from "@/common/config.js";
Vue.use(Slider);

export default {
  name: "SearchTrain",
  components: { List, LoadingCircle },
  computed: {
    ...mapGetters(["hotelList", "hotelOrderInfo"]),
    // 筛选ids  组件默认是多选或全部选项卡单选，改成单个选项卡单选
    filter_activeIds: {
      get() {
        return this.ticket_filter_activeIds;
      },
      set(val) {
        let result = [];
        // 匹配项
        let region = this.ticket_filter_items[0].children.map((e) => e.id);
        let star = this.ticket_filter_items[1].children.map((e) => e.id);
        // let logo = this.ticket_filter_items[2].children.map(e => e.id);
        let filter = {
          regin: true,
          star: true,
        };
        for (let i = val.length - 1; i >= 0; i--) {
          const element = val[i];
          if (region.includes(element)) {
            if (filter.regin) {
              filter.regin = false;
              result.push(element);
            }
          } else if (star.includes(element)) {
            if (filter.star) {
              filter.star = false;
              result.push(element);
            }
          } else {
            result.push(element);
          }
        }
        this.ticket_filter_activeIds = result;
      },
    },
    // 筛选项目，通过ticketList计算得出
    ticket_filter_items: function () {
      let selectList = [];
      // 地区
      let disarr = [];
      if (this.district)
        this.district.forEach((element) => {
          disarr.push({
            text: element.nameCn,
            id: element.nameCn + ":" + element.id,
          });
        });
      selectList.push({
        text: "地区",
        children: disarr,
      });
      // 星级
      selectList.push({
        text: "星级",
        children: [
          { text: "一星 / 普通", id: 1 },
          { text: "二星 / 经济", id: 2 },
          { text: "三星 / 舒适", id: 3 },
          { text: "四星 / 高档", id: 4 },
          { text: "五星 / 豪华", id: 5 },
        ],
      });
      // 服务商
      let corparr = new Set(HotelServiceProvider);
      selectList.push({
        text: "服务商",
        children: [...corparr].map((data) => {
          return { text: data, id: data };
        }),
      });
      return selectList;
    },
    // 请求参数
    query: function () {
      let localID = "",
        localName = "";
      this.ticket_filter_activeIds.forEach((element) => {
        if (typeof element == "string" && element.includes(":")) {
          let temp = element.split(":");
          localID = temp[1];
          localName = temp[0];
        }
      });
      return {
        checkInDate: this.hotelOrderInfo.checkInDate, // 顶部-入住时间
        checkOutDate: this.hotelOrderInfo.departDate, // 顶部-离开时间
        city: this.hotelOrderInfo.city, // 顶部-城市
        locationId: localID, // 筛选-地区ID
        locationName: localName, // 筛选-地区名
        sort: this.sort, // 排序
        key: this.hotelOrderInfo.keyword, // 顶部-用户输入的关键字
        radius: 8000, // 搜索半径
        // 筛选-星级
        star:
          parseInt(
            this.ticket_filter_activeIds
              .filter((e) => typeof e == "number")
              .join("")
          ) || 0,
        rangePrice: [
          // 筛选-价格
          this.priceRange[0],
          this.priceRange[1] == 1000 ? 99999 : this.priceRange[1],
        ].join("-"),
        longitude: this.position.longitude, // 顶部-关键字或城市的经纬度
        latitude: this.position.latitude,
        currentIndex: this.page,
        pageSize: this.pageSize * 5,
        corp: "大唐",
      };
    },
  },
  activated() {
    window.setTitle(this.hotelOrderInfo.city + "酒店信息");
  },
  mounted() {
    // 百度地图API异步，所以用回调
    searchPosition(
      this.hotelOrderInfo.keyword,
      this.hotelOrderInfo.city,
      (position) => {
        this.position.longitude = position.lng; // 初始化经纬度
        this.position.latitude = position.lat; // 初始化经纬度
        this.fetchDistrict(); // 获取省份
        this.onRefresh(); // 拉取列表
      }
    );
  },
  data() {
    return {
      currentlist: init(), // 分发ajax的对象
      position: null, // 坐标
      sort: "DA", // 排序  PA价格升序 PD价格降序
      position: {}, // 经纬度
      priceRange: [100, 1000], // 价格区间
      marks: {
        0: {
          label: "￥0",
          style: {
            "font-size": "10px",
          },
        },
        1000: {
          label: "￥900以上",
          style: {
            "white-space": "nowrap",
            "font-size": "10px",
            transform: "translateX(-40px)",
          },
        },
      },
      loading: false, // 下拉刷新
      finished: false, // 下拉刷新
      refreshing: false, // 下拉刷新
      activenav: "early", // 底部四个按钮导航 变蓝
      page: 1, // 页码
      pageSize: 20, // 每页条数
      total: 10, // 总数
      showList: [], // 显示的列表
      templist: {}, // 缓存列表
      queryParam: {},
      isLoading: false,
      show_calendar: false, // 日历开关
      //筛选bar中的详细筛选
      ticket_filter_show: false,
      ticket_filter_activeIds: [], // 筛选框中已选值（地区以“：”做判断，其他选项请避免这个符号）
      ticket_filter_activeIndex: 0,
      skeletonArr: SkeletonArr, //骨架样式数据
      district: [],
      hideLoading: false, // 遮罩层
      havemore: true, // 是否提示还有更多
    };
  },
  methods: {
    // 按价格距离排序的排序方法
    sortSelf(type) {
      this.currentlist.sort({ sort: this.sort }, this.ticket_filter_activeIds);
      this.showList = this.currentlist.list;
      ToTop();
    },
    // 筛选确认
    filter() {
      this.ticket_filter_show = false;
      this.onRefresh();
    },
    // 选星
    toggle(index) {
      this.$refs.starDom[index].toggle();
    },
    // 选择城市 转到选择城市页面 参数是vuex的actions名称
    chooseCity(vuxFunction = "hotel/setHotelOrderFrom") {
      this.hideLoading = true;
      setTimeout(() => {
        this.$router.push({
          path: "/selectCity",
          query: logic.queryPathEncrypt({ function: vuxFunction }),
        });
      }, 100);
    },
    // 日历选择日期
    calendarConfirm(date) {
      this.$store.dispatch("hotel/setHotelOrder", {
        checkInDate: date[0],
        departDate: date[1],
      });
      this.show_calendar = false;
      this.onRefresh();
    },
    // 搜索
    fetchList() {
      this.finished = true;
      this.currentlist.update(this.query, this.ticket_filter_activeIds);
    },
    // 加载更多
    async onLoad() {
      if (this.page * this.pageSize < this.total) {
        this.finished = false;
      }
      if (!this.position.hasOwnProperty("latitude")) return; // 如果没坐标就先别加载列表，坐标获取失败会默认为0
      if (this.refreshing) {
        this.showList = [];
        this.page = 1;
        this.refreshing = false;
      }
      await this.fetchList();
      this.page++;
      this.loading = false;
      this.finished = true;
    },
    // 下拉重置
    onRefresh() {
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.currentlist.resetList();
      this.onLoad();
    },
    //❤️❤️核心：选好票啦~！❤️❤️
    chooseTicket(ticketInfo) {
      this.$store.dispatch("hotel/setHotelTicket", ticketInfo);
      this.$router.push("/detailHotel");
    },
    // 拉取城市列表
    fetchDistrict() {
      getHotelDistrict({
        corp: "大唐",
        cityCode: this.hotelOrderInfo.city,
      }).then((response) => {
        this.district = response.data;
      });
    },
  },
  filters: {
    showPriceRange(item = [0, 1000]) {
      if (item[1] == 1000) {
        if (item[0] == 0) return "全部价格";
        return "￥" + item[0] + "以上";
      }
      return `￥${item[0]}-${item[1]}`;
    },
  },
  // ⭐⭐监听列表源变化⭐⭐
  watch: {
    "currentlist.check": {
      handler: function () {
        // 如果全部厂商数据获取完毕，排序，追加到渲染数组中
        if (this.currentlist.supplier.length == HotelServiceProvider.length) {
          this.currentlist.sort(this.query, this.ticket_filter_activeIds);
          this.total = this.currentlist.total; // 多个服务商的总条目数
          if (this.page * this.pageSize < this.total) {
            this.finished = false; // 允许继续加载酒店数据
            this.havemore = true; // 提示有更多酒店
          } else {
            this.havemore = false; // 提示没有更多酒店了
          }
          this.showList = this.currentlist.list; // 给了showList就渲染到页面了
        }
      },
    },
  },
};
</script>

<style lang="less" src="@/styles/searchCommon.less" scoped></style>
<style scoped>
.tab_bar_filter_content {
  padding-top: 0;
}
.slider-title {
  margin: 0;
  padding-left: 1rem;
  text-align: left;
}
.slider-cont {
  padding: 0 1.5rem;
}
.deep-blue {
  color: #0066ff;
}
.top-city,
.top-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.top-date {
  padding: 0 0.4rem;
  transform: scale(0.9);
  font-size: 10px;
  line-height: 14px;
  border: 1px solid rgb(136, 155, 177);
  border-top: 0;
  border-bottom: 0;
}
.top-icon {
  padding: 2px 0;
}
.van-search__action {
  padding: 0 0.8rem;
}
.van-search__action:active {
  background-color: #2d51d5;
}
.min-hei {
  min-height: calc(100vh - 108px);
  padding: 6px;
}
</style>
<style lang="less">
.searchTrain {
  .van-field__control {
    margin-top: 2px;
  }
  .van-icon-clear {
    color: #f7f7f7;
  }
}
</style>